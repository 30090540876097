/**
 * React apps are center-aligned by default... but that messes
 * up bootstrap labels and other components. So let's override it.
 */
.App {
  text-align: left !important;
}

/**
 * Hub green color for the navbar
 */
.navbar{
  background:#A6CE39;
}

/**
 * Navbar link styling
 */
.navbar a.nav-link:hover{
  color:white;
}
.navbar a.active{
  text-decoration: underline;
}

/**
 * Used to show a modal by default.
 * This can be used to show a bootstrap modal purely with react.
 * eg. by setting className="modal show" when rendering a component,
 * and simply not rendering it (using states) if you don't want it to show.
 */
div.modal.show{
  display:block !important;
  background:#0003;
}

/**
 * Used to hide an element
 */
.hide{
  display:none;
}

/**
 * For hover tables, use 'pointer' (the hand icon) when hovering over
 * a row to indicate that it can be clicked in order to perform an action.
 */
table.table-hover > tbody > tr {
  cursor:pointer;
}

.float-right{
  float:right;
}
.align-left{
    text-align:left;
}
.align-right{
    text-align:right;
}
