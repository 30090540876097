.fullscreen-map{
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: #000a;
  padding:10px;
}
.fullscreen-map > .header{
  height:40px;
}
.fullscreen-map > .body{
  height:calc(100% - 40px);
}
